import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { AuthService } from "src/app/services/auth/auth.service";
import Utilities from "src/app/services/utilities";
import * as Config from "src/config";
import { ServerSideConfigData } from "src/model/modelDto";
import invariant from "tiny-invariant";
import { BaseService } from "../base-service";
import { setSiteTheme } from "../unit-of-work/unit-of-work.service";

export const configDataLoaded$ = new ReplaySubject<boolean>(1);

@Injectable({
  providedIn: "root",
})
export class ServerSideConfigDataService extends BaseService {
  configData: ServerSideConfigData | undefined;

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
  ) {
    super(http, "", authService);
  }

  loadServerSideData(): Promise<boolean> {
    if (!this.configData) {
      return this.ensureValidTokenObs.toPromise().then((_) =>
        this.http
          .get<ServerSideConfigData>(
            `${Config.serviceName}/getServerSideConfigData`,
          )
          .toPromise()
          .then((result) => {
            invariant(result !== undefined);
            this.configData = result;

            invariant(result.hashes[0]);

            Config.hashes.currentMetadataHash = result.hashes[0].metadataHash;
            Config.hashes.currentAnnotatedMetadataHash =
              result.hashes[0].annotatedMetadataHash;
            Config.hashes.currentLookupsHash = result.hashes[0].lookupsHash;

            window.__user = {
              isValidUser: result.isValidUser,
              isAdmin: result.isAdmin,

              showTranslator: result.showTranslator,
              showGPTAnalytics: result.showGPTAnalytics,
              showGPTDocSearch: result.showGPTDocSearch,
              showVision: result.showVision,
              showDocChat: result.showDocChat,
            };

            invariant(result.adminSettings, "Admin settings are missing");
            window.__adminSettings = result.adminSettings;

            invariant(result.licenseModel, "License model is missing");
            window.__licenseModel = result.licenseModel;

            invariant(result.appContent, "App content is missing");
            window.__appContent = result.appContent as any;

            const color = result.adminSettings.preferredColor;
            if (color) {
              setSiteTheme(color);
            }

            configDataLoaded$.next(true);
            return true;
          })
          .catch((err) => Promise.reject(err)),
      );
    } else {
      return Utilities.valueAsPromise(true);
    }
  }
}
